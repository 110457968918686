import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  // API Related configs
  //public apiPort: string = "9090";
  public apiPort: string = "8080";
  public contextPath: string = "hrms";
  public apiProtocol: string;
  public apiHostName: string;
  public baseApiPath: string;
 
  //values could be local, keycloak, local-keycloak
  public authenticationConfigurationType:string = "keycloak";
  public environment:string = "dev";
  
 //Local config
  public tempApiPath; 
  public identityServerUrl; 
  public recruitUrl;
  public peopleUrl;
  public adminUrl;
  public timesheetUrl;
 
  // public tempApiPath = 'https://lms.tenupsoft.com/';
  // public identityServerUrl="https://identity.tenupsoft.com/auth/realms/Tenup/protocol/openid-connect/auth?response_type=code&client_id=hrms&redirect_uri=https%3A%2F%2Fhrms.tenupsoft.com%2Fapi%2Fv1%2Fgateway";
 
  constructor() {
    if(this.environment=='local'){
    this.tempApiPath = 'http://localhost:8080/hrms/';
    this.identityServerUrl="https://identity.tenupsoft.com/auth/realms/Tenup/protocol/openid-connect/auth?response_type=code&client_id=local-hrms&redirect_uri=http%3A%2F%2Flocalhost%3A9090%2Fhrms%2Fapi%2Fv1%2Fgateway";
    this.recruitUrl = 'http://localhost:8080/hrms/';
    this.peopleUrl = 'http://localhost:8080/hrms/';
    this.adminUrl = 'http://localhost:8080/hrms/';  
    this.timesheetUrl = 'http://localhost:8080/hrms/';
    }else if(this.environment=='local-keycloak'){
    this.tempApiPath = 'http://localhost:8080/hrms/';
    this.identityServerUrl="https://identity.tenupsoft.com/auth/realms/TenupDev/protocol/openid-connect/auth?response_type=code&client_id=local-hrms&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fhrms%2Fapi%2Fv1%2Fgateway";
    this.recruitUrl = 'http://localhost:8080/hrms/';
    this.peopleUrl = 'http://localhost:8080/hrms/';
    this.adminUrl = 'http://localhost:8080/hrms/';
    this.timesheetUrl = 'http://localhost:8080/hrms/';
    }else if(this.environment=='dev'){
    this.tempApiPath = 'https://dev.hrms.tenupsoft.com/';
    this.identityServerUrl="https://identity.tenupsoft.com/auth/realms/TenupDev/protocol/openid-connect/auth?response_type=code&client_id=hrms-dev&redirect_uri=https%3A%2F%2Fdev.lms.tenupsoft.com%2Fapi%2Fv1%2Fgateway";
    this.recruitUrl = 'https://dev.hrms.tenupsoft.com/';
    this.peopleUrl = 'https://dev.people.tenupsoft.com/';
    this.adminUrl = 'https://dev.admin.tenupsoft.com/';
    this.timesheetUrl = 'https://dev.timesheet.tenupsoft.com/';    
    }else if(this.environment=='prod'){
      this.tempApiPath = 'https://lms.tenupsoft.com/';
      this.identityServerUrl = "https://identity.tenupsoft.com/auth/realms/Tenup/protocol/openid-connect/auth?response_type=code&client_id=hrms&redirect_uri=https%3A%2F%2Flms.tenupsoft.com%2Fapi%2Fv1%2Fgateway";
      this.recruitUrl = 'https://recruit.tenupsoft.com/';
      this.peopleUrl = 'https://people.tenupsoft.com/';
      this.adminUrl ='https://admin.tenupsoft.com/';
      this.timesheetUrl ='https://timesheet.tenupsoft.com/';
    }
    
    if (this.apiProtocol === undefined) {
      this.apiProtocol = window.location.protocol;
    }
    if (this.apiHostName === undefined) {
      this.apiHostName = window.location.hostname;
    }
    if (this.apiPort === undefined) {
      this.apiPort = window.location.port;
    }

    this.baseApiPath = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/" + this.contextPath + "/";

  }
}
